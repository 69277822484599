//Base Font Size
$--font-base: 16px;

//Paddings
$--padding-container: clamp(2rem, 6vw, 6rem);

//Color Scheme
$--color-white: white;
$--color-black: black;
$--color-grey: #707070;
$--color-grey-dark: #282c34;
$--color-purple: #a747cd;

$--color-primary: #284fc4;
$--color-secondary: #2141c0;
$--color-tertiary: #030a60; //#F5D7D1;
$--color-brown: white;
$--color-brown-dark: #335967;

//Transition
$--ease-hover: 0.3s ease;
$--jelly-hover: 0.4s cubic-bezier(0.57, 0.04, 1, 0.83);

//Animation
@keyframes basic-bounce {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(10%);
  }
}

@keyframes jelly-bounce {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1, 0.9);
  }
  65% {
    transform: scale(0.9, 1.05);
  }
  70% {
    transform: scale(1.08, 0.95);
  }
  80% {
    transform: scale(0.95, 1.02);
  }
  90% {
    transform: scale(1.05, 0.98);
  }
  100% {
    // transform: scale(1.03, 0.99);
    transform: scale(1);
  }
}
