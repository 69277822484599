// @mixin font-title {
//   font-family: 'Bodoni Moda', serif;
// }

// @mixin font-body {
//   font-family: 'Poiret One', cursive;
// }

:root {
  font-size: $--font-base;
}

body {
  background-color: $--color-primary;
  margin: 0;
  // @include font-body();
  font-weight: 300;

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

footer {
  margin-top: auto;
}

h1 {
  // @include font-title();
  font-size: pxrem(60);

  @media screen and (max-width: 1199px) {
    font-size: pxrem(52);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(48);
  }
  @media screen and (max-width: 767px) {
    font-size: pxrem(36);
  }
  @media screen and (max-width: 575px) {
    font-size: pxrem(32);
  }
}

h2 {
  // @include font-title();
  font-size: pxrem(52);

  @media screen and (max-width: 1199px) {
    font-size: pxrem(48);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(36);
  }
  @media screen and (max-width: 767px) {
    font-size: pxrem(32);
  }
}

h3 {
  font-size: pxrem(36);
}

h4 {
  font-size: pxrem(24);
}

h5 {
  font-size: pxrem(20);
}

a {
  // color: $--color-brown-dark;
  color: $--color-brown;
  text-decoration: none;
}

p {
  margin: 0;
}

button,
.btn {
  // @include font-body();
  color: inherit;
  font-size: inherit;
  background: none;
  margin: 0;
  border: 0;
  cursor: pointer;
}
