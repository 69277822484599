.header {
  position: fixed;
  left: 0;
  right: 0;
  padding: pxrem(20 0);
  z-index: 999;
  color: $--color-brown-dark;
  transition: backdrop-filter 0.3s;
  backdrop-filter: blur(20px) opacity(0);

  &.scrolled {
    backdrop-filter: blur(20px) opacity(1);
  }

  > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    font-size: pxem(24);
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__item {
    &:not(:first-child) {
      margin-left: pxem(40);
    }
  }

  &__link {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: pxrem(0);
      height: pxrem(2);
      background-color: $--color-brown-dark;
      transition: width 0.3s ease;
    }

    &:hover,
    &:focus,
    &.active {
      &:after {
        width: pxrem(20);
      }
    }
  }

  &__icon {
    cursor: pointer;

    path {
      fill: $--color-brown;
    }

    &--menu {
      display: none;

      @media screen and (max-width: 991px) {
        display: block;
      }
    }
  }
}

.myname {
  display: flex;

  &__copyright {
    transform: rotate(0.001deg);
    transition: transform $--ease-hover;
    .myname:hover &,
    .myname:focus & {
      transform: rotate(360deg);
    }
  }

  &__text {
    position: relative;
    overflow: hidden;
    margin-left: 5px;
    transition: padding $--ease-hover;
    .myname:hover &,
    .myname:focus & {
      padding-right: 5em;
    }
  }

  &__scroll {
    display: inline-block;
    transition: transform $--ease-hover;
    .myname:hover &,
    .myname:focus & {
      transform: translateX(-70px);
    }
  }

  &__hidden {
    position: absolute;
    white-space: nowrap;
    padding-left: 0.5ex;
  }
}

.drawer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{pxem(30)});
  padding: pxem(20) 0;

  &__head {
    // @include font-title();
    font-size: pxem(18);
    color: $--color-brown-dark;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: pxem(30, 18);
    padding-right: pxem(30, 18);
    padding-bottom: pxem(12, 24);
    margin-bottom: pxem(24, 24);
    border-bottom: 1px solid $--color-primary;

    svg {
      cursor: pointer;
      path {
        fill: $--color-brown-dark;
      }
    }
  }
  &__content {
      display: flex;
      flex-direction: column;
      color: $--color-brown-dark;
      padding-left: pxem(24);
      padding-right: pxem(24);
      &--empty {
        font-size: pxem(20);
        align-items: center;
        justify-content: center;
        height: 100%;
      }
  }

  &__foot {
    display: flex;
    margin-top: auto;
    padding-left: pxem(24);
    padding-right: pxem(24);
    svg {
      cursor: pointer;
      path {
        fill: $--color-brown-dark;
      }
      & + svg {
        margin-left: pxem(12);
      }
    }
  }
}
