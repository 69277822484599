.hero {
  position: relative;
  display: flex;
  height: 100vh;
  color: $--color-brown;
  font-size: pxrem(18);

  @media screen and (max-width: 1199px) {
    font-size: pxrem(17);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(16);
  }

  > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 4em;
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__title {
    position: relative;
    margin: 0;
    padding-bottom: pxrem(20);
    letter-spacing: 0.2rem;

    &:after {
      content: '';
      position: absolute;
      width: 25%;
      height: pxrem(2);
      left: 0;
      bottom: 0;
      background-color: $--color-brown;
    }
  }

  &__desc {
    margin: pxem(24, 18) 0;
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: pxem(20, 18);
    padding: pxem(20 36, 20);
    border: 1px solid $--color-brown;
    background-color: rgba($--color-brown, 0);
    transition: $--ease-hover;
    span + svg {
      margin-left: pxrem(16);
      transition: $--ease-hover;
    } 
    path {
      transition: $--ease-hover;
    }

    @media screen and (max-width: 1199px) {
      color: $--color-primary;
      background-color: $--color-brown;
      svg {      
        path {
          fill: $--color-primary;
        }
      }
    }

    &:hover, &:focus {
      color: $--color-primary;
      background-color: $--color-brown;
      svg {      
        path {
          fill: $--color-primary;
        }
        transform: translateX(5px);
      }
    }
  }

}

.certificate {
  position: relative;
  margin-top: pxem(30);
  padding-bottom: pxem(50);
  color: $--color-brown;

  @media screen and (max-width: 1199px) {
    font-size: pxrem(15);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(14);
  }
  @media screen and (max-width: 767px) {
    font-size: pxrem(12);
  }

  h2 {
    text-align: center;
  }

  &:before {
    content: '';
    position: absolute;
    top: pxem(40);
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $--color-secondary;
    z-index: -1;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: pxem(30);

    @media screen and (max-width: 991px) {
      display: flex;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media screen and (max-width: 575px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 991px) {
      flex: 0 0 calc(#{percentage(1/3)} - #{pxem(20)});
    }
    @media screen and (max-width: 575px) {
      flex: 0 0 calc(#{percentage(1/2)} - #{pxem(15)});
    }
  }

  &__imgframe {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__img {
    width: 100%;
    margin-bottom: pxem(56);

    @media screen and (max-width: 991px) {
      margin-bottom: pxem(36);
    }

    @media screen and (max-width: 767px) {
      margin-bottom: pxem(24);
    }
  }

  &__name {
    // @include font-title();
    display: block;
    font-size: pxem(24);
    margin-bottom: pxem(24, 24);
  }

  &__desc {
    font-size: pxem(18);
  }
}

.product {
  position: relative;
  margin-top: pxem(30);
  padding-bottom: pxem(50);
  color: $--color-brown;

  @media screen and (max-width: 1199px) {
    font-size: pxrem(15);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(14);
  }
  @media screen and (max-width: 767px) {
    font-size: pxrem(12);
  }

  h2 {
    text-align: center;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: pxem(80);

    @media screen and (max-width: 991px) {
      gap: pxem(60);
    }
    @media screen and (max-width: 767px) {
      gap: pxem(40);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      max-width: 80%;
      margin: auto;
    }
  }

  &__placeholder {
    position: relative;
    height: 0;
    padding-bottom: percentage(350 / 400);
    overflow: hidden;
    margin-bottom: pxem(30);
  }

  &__img {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  &__name {
    // @include font-title();
    display: block;
    font-size: pxem(24);
    margin-bottom: pxem(24, 24);
  }
}

.aboutus {
  position: relative;
  padding-top: pxem(182);
  padding-bottom: pxem(36);
  margin-bottom: pxem(108);
  color: $--color-brown;

  @media screen and (max-width: 1199px) {
    font-size: pxrem(15);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(14);
    padding-top: pxrem(422);
  }
  @media screen and (max-width: 767px) {
    font-size: pxrem(12);
  }
  @media screen and (max-width: 575px) {
    padding-top: pxrem(260);
  }

  &:before {
    content: '';
    position: absolute;
    top: pxem(120);
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $--color-secondary;
    z-index: -1;
  }

  &__bg {
    position: absolute;
    width: percentage(900 / 1440); 
    max-width: pxrem(900);
    margin-top: pxem(-182);
    z-index: -1;
    @media screen and (max-width: 991px) {
      margin-top: pxrem(-422);
    }
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    @media screen and (max-width: 575px) {
      margin-top: pxrem(-260);
    }
  }

  &__textbox {
    background-color: $--color-primary;
    width: percentage(595 / 1360);
    margin-left: auto;
    margin-bottom: pxem(77);
    padding: pxem(30 40);
    
    p {
      font-size: pxem(24);
      margin-bottom: pxem(24, 24);
    }

    @media screen and (max-width: 991px) {
      width: 70%;
      margin-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      width: 80%;
    }
  }
}