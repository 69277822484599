@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function pxrem($value, $base: strip-unit($--font-base)) {
  $remValue: ();
  @each $arg in $value {
    $remValue: append($remValue, unquote(($arg / $base) + "rem"), "space");
  }
  @return $remValue;
}

@function pxem($value, $base: strip-unit($--font-base)) {
  $emValue: ();
  @each $arg in $value {
    $emValue: append($emValue, unquote(($arg / $base) + "em"), "space");
  }
  @return $emValue;
}
