.container {
  max-width: pxrem(1600);
  margin: 0 auto;
  padding-left: $--padding-container;
  padding-right: $--padding-container;
  &--inner {
    padding-left: calc(#{$--padding-container} * 2);
    padding-right: calc(#{$--padding-container} * 2);
  }
  &--outer {
    padding-left: calc(#{$--padding-container} / 2);
    padding-right: calc(#{$--padding-container} / 2);
  }
}

.skip-nav {
  position: fixed;
  left: 0;
  right: 0;
  padding: pxrem(20);
  background-color: $--color-white;
  z-index: 1000;
  transition: transform 0.3s ease;

  &:not(:focus):not(:active) {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    transform: translateY(-100%);
  }
}

.jelly-bounce {
  &:hover,
  &:focus {
    animation: jelly-bounce 0.8s forwards;
  }
}
