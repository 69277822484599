.footer {
  padding-top: pxem(40);
  background-color: $--color-brown;
  color: $--color-primary;
  
  a {
    color: $--color-primary;
  }

  @media screen and (max-width: 1199px) {
    font-size: pxrem(15);
  }
  @media screen and (max-width: 991px) {
    font-size: pxrem(14);
  }
  @media screen and (max-width: 767px) {
    font-size: pxrem(12);
  }

  &__upper {
    margin-bottom: pxem(70);
  }

  &__lower {
    padding: pxrem(20 0);
    margin-bottom: pxem(50);
    text-transform: uppercase;

    > .container {
      display: flex;
    }

    .footer__link + .footer__link {
      margin-left: pxem(20);
    }
  }

  &__text {
    font-size: pxem(24);
    transition: color $--ease-hover;

    & + .footer__text {
      margin-top: pxem(24, 24);
    }

    &:not(p):hover {
      color: $--color-brown-dark;
    }
  }

  &__logo {
    font-size: pxem(36);
    margin-bottom: pxem(20, 36);
  }

  &__slogan {
    // @include font-title();
    font-size: pxem(52);
    line-height: pxem(60, 52);
  }

  &__grid {
    display: grid;
    grid-template-columns: 1.8fr repeat(2, 1fr);
    gap: pxem(60);

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      &:first-child {
        grid-column: span 2;
      }
    }
  }
}
