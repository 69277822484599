.jellybean {
  position: relative;
  display: inline-flex;
  width: fit-content;
  height: pxem(48);
  white-space: nowrap;
  border-radius: pxem(24);
  padding: pxem(0 24);
  color: $--color-white;
  transition: transform $--jelly-hover;

  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    transition: transform $--jelly-hover;
    background-color: $--color-purple;
    overflow: hidden;

    &:before,
    &:after {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: inherit;
      transition: transform $--jelly-hover;
    }

    &:before,
    &:after {
      content: "";
      transform: scaleX(0);
      transform-origin: right center;
    }

    &:before {
      background-color: lighten($--color-purple, 4%);
      transition-delay: 0.2s;
    }

    &:after {
      background-color: lighten($--color-purple, 8%);
      transition-delay: 0s;
    }

    .jellybean:hover &,
    .jellybean:focus & {
      animation: jelly-bounce 0.8s forwards;
      &:before,
      &:after {
        transform: scaleX(1);
        transform-origin: left center;
      }
      &:before {
        transition-delay: 0s;
      }
      &:after {
        transition-delay: 0.2s;
      }
    }
  }

  &__text {
    display: flex;
    align-items: center;
    z-index: 1;
  }
}
